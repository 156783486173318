<template>
    <div class="products">
      <div class="page-header">
        <h1>{{ $t('Seats Management')}} > {{ $t('Seats (List)') }}</h1>
      </div>
      <div class="page-content">
        <div class="content-header">
          <h2>{{ $t('Seats (List)') }}</h2>
          <el-button class="action-button" type="primary">{{ $t('Add Seats') }}</el-button>
        </div>
  
        <div class="seat-list">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">{{ $t('Seat Number') }}</th>
              <th scope="col">{{ $t('Status') }}</th>
              <th scope="col">{{ $t('Actions') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
            <td>1</td>
            <td>可用餐 (開放中)</td>
            <td>
                <div class="action-links">
                    <a class="action-link" href="#">{{ $t('下單') }}</a>
                    <a class="action-link" href="#">{{ $t('拆枱') }}</a>
                    <a  class="action-link"href="#">{{ $t('鎖枱') }}</a>
                </div>
            </td>
            </tr>
            <tr>
            <td>2</td>
            <td>可用餐 (開放中)</td>
            <td>
                <div class="action-links">
                    <a class="action-link" href="#">{{ $t('下單') }}</a>
                    <a class="action-link" href="#">{{ $t('拆枱') }}</a>
                    <a  class="action-link"href="#">{{ $t('鎖枱') }}</a>
                </div>
            </td>
            </tr>
            <tr>
            <td>3</td>
            <td>不可用餐 (未開放)</td>
            <td>
                <div class="action-links">
                    <a class="action-link" href="#">{{ $t('下單') }}</a>
                    <a class="action-link" href="#">{{ $t('拆枱') }}</a>
                    <a  class="action-link"href="#">{{ $t('鎖枱') }}</a>
                </div>
            </td>
            </tr>
            <tr>
            <td>4</td>
            <td>可用餐 (開放中)</td>
            <td>
                <div class="action-links">
                    <a class="action-link" href="#">{{ $t('下單') }}</a>
                    <a class="action-link" href="#">{{ $t('拆枱') }}</a>
                    <a  class="action-link"href="#">{{ $t('鎖枱') }}</a>
                </div>
            </td>
            </tr>
            <tr>
            <td>5</td>
            <td>可用餐 (開放中)</td>
            <td>
                <div class="action-links">
                    <a class="action-link" href="#">{{ $t('下單') }}</a>
                    <a class="action-link" href="#">{{ $t('拆枱') }}</a>
                    <a  class="action-link"href="#">{{ $t('鎖枱') }}</a>
                </div>
            </td>
            </tr>
            <tr>
            <td>6</td>
            <td>可用餐 (開放中)</td>
            <td>
                <div class="action-links">
                    <a class="action-link" href="#">{{ $t('下單') }}</a>
                    <a class="action-link" href="#">{{ $t('拆枱') }}</a>
                    <a  class="action-link"href="#">{{ $t('鎖枱') }}</a>
                </div>
            </td>
            </tr>
            <tr>
            <td>7</td>
            <td>用餐中 (開放中)</td>
            <td>
                <div class="action-links">
                    <a class="action-link" href="#">{{ $t('下單') }}</a>
                    <a class="action-link" href="#">{{ $t('拆枱') }}</a>
                    <a  class="action-link"href="#">{{ $t('鎖枱') }}</a>
                </div>
            </td>
            </tr>
            <tr>
            <td>11</td>
            <td>可用餐 (開放中)</td>
            <td>
                <div class="action-links">
                    <a class="action-link" href="#">{{ $t('下單') }}</a>
                    <a class="action-link" href="#">{{ $t('拆枱') }}</a>
                    <a  class="action-link"href="#">{{ $t('鎖枱') }}</a>
                </div>
            </td>
            </tr>
            <tr>
            <td>12</td>
            <td>用餐中 (開放中)</td>
            <td>
                <div class="action-links">
                    <a class="action-link" href="#">{{ $t('下單') }}</a>
                    <a class="action-link" href="#">{{ $t('拆枱') }}</a>
                    <a  class="action-link"href="#">{{ $t('鎖枱') }}</a>
                </div>
            </td>
            </tr>
            <tr>
            <td>13</td>
            <td>用餐中 (開放中)</td>
            <td>
                <div class="action-links">
                    <a class="action-link" href="#">{{ $t('下單') }}</a>
                    <a class="action-link" href="#">{{ $t('拆枱') }}</a>
                    <a  class="action-link"href="#">{{ $t('鎖枱') }}</a>
                </div>
            </td>
            </tr>
            <tr>
            <td>14</td>
            <td>可用餐 (開放中)</td>
            <td>
                <div class="action-links">
                    <a class="action-link" href="#">{{ $t('下單') }}</a>
                    <a class="action-link" href="#">{{ $t('拆枱') }}</a>
                    <a  class="action-link"href="#">{{ $t('鎖枱') }}</a>
                </div>
            </td>
            </tr>
            <tr>
            <td>21</td>
            <td>可用餐 (開放中)</td>
            <td>
                <div class="action-links">
                    <a class="action-link" href="#">{{ $t('下單') }}</a>
                    <a class="action-link" href="#">{{ $t('拆枱') }}</a>
                    <a  class="action-link"href="#">{{ $t('鎖枱') }}</a>
                </div>
            </td>
            </tr>
            <tr>
            <td>22</td>
            <td>用餐中 (開放中)</td>
            <td>
                <div class="action-links">
                    <a class="action-link" href="#">{{ $t('下單') }}</a>
                    <a class="action-link" href="#">{{ $t('拆枱') }}</a>
                    <a  class="action-link"href="#">{{ $t('鎖枱') }}</a>
                </div>
            </td>
            </tr>
            <tr>
            <td>23</td>
            <td>用餐中 (開放中)</td>
            <td>
                <div class="action-links">
                    <a class="action-link" href="#">{{ $t('下單') }}</a>
                    <a class="action-link" href="#">{{ $t('拆枱') }}</a>
                    <a  class="action-link"href="#">{{ $t('鎖枱') }}</a>
                </div>
            </td>
            </tr>
            <tr>
            <td>24</td>
            <td>用餐中 (開放中)</td>
            <td>
                <div class="action-links">
                    <a class="action-link" href="#">{{ $t('下單') }}</a>
                    <a class="action-link" href="#">{{ $t('拆枱') }}</a>
                    <a  class="action-link"href="#">{{ $t('鎖枱') }}</a>
                </div>
            </td>
            </tr>
            <tr>
            <td>31</td>
            <td>可用餐 (開放中)</td>
            <td>
                <div class="action-links">
                    <a class="action-link" href="#">{{ $t('下單') }}</a>
                    <a class="action-link" href="#">{{ $t('拆枱') }}</a>
                    <a  class="action-link"href="#">{{ $t('鎖枱') }}</a>
                </div>
            </td>
            </tr>
            <tr>
            <td>32</td>
            <td>可用餐 (開放中)</td>
            <td>
                <div class="action-links">
                    <a class="action-link" href="#">{{ $t('下單') }}</a>
                    <a class="action-link" href="#">{{ $t('拆枱') }}</a>
                    <a  class="action-link"href="#">{{ $t('鎖枱') }}</a>
                </div>
            </td>
            </tr>
            <tr>
            <td>33</td>
            <td>可用餐 (開放中)</td>
            <td>
                <div class="action-links">
                    <a class="action-link" href="#">{{ $t('下單') }}</a>
                    <a class="action-link" href="#">{{ $t('拆枱') }}</a>
                    <a  class="action-link"href="#">{{ $t('鎖枱') }}</a>
                </div>
            </td>
            </tr>
            <tr>
            <td>34</td>
            <td>可用餐 (開放中)</td>
            <td>
                <div class="action-links">
                    <a class="action-link" href="#">{{ $t('下單') }}</a>
                    <a class="action-link" href="#">{{ $t('拆枱') }}</a>
                    <a  class="action-link"href="#">{{ $t('鎖枱') }}</a>
                </div>
            </td>
            </tr>
            <tr>
            <td>35</td>
            <td>可用餐 (開放中)</td>
            <td>
                <div class="action-links">
                    <a class="action-link" href="#">{{ $t('下單') }}</a>
                    <a class="action-link" href="#">{{ $t('拆枱') }}</a>
                    <a  class="action-link"href="#">{{ $t('鎖枱') }}</a>
                </div>
            </td>
            </tr>
            <tr>
            <td>36</td>
            <td>可用餐 (開放中)</td>
            <td>
                <div class="action-links">
                    <a class="action-link" href="#">{{ $t('下單') }}</a>
                    <a class="action-link" href="#">{{ $t('拆枱') }}</a>
                    <a  class="action-link"href="#">{{ $t('鎖枱') }}</a>
                </div>
            </td>
            </tr>
            <tr>
            <td>37</td>
            <td>可用餐 (開放中)</td>
            <td>
                <div class="action-links">
                    <a class="action-link" href="#">{{ $t('下單') }}</a>
                    <a class="action-link" href="#">{{ $t('拆枱') }}</a>
                    <a  class="action-link"href="#">{{ $t('鎖枱') }}</a>
                </div>
            </td>
            </tr>
            <tr>
            <td>38</td>
            <td>用餐中 (開放中)</td>
            <td>
                <div class="action-links">
                    <a class="action-link" href="#">{{ $t('下單') }}</a>
                    <a class="action-link" href="#">{{ $t('拆枱') }}</a>
                    <a  class="action-link"href="#">{{ $t('鎖枱') }}</a>
                </div>
            </td>
            </tr>
            <tr>
            <td>41</td>
            <td>用餐中 (開放中)</td>
            <td>
                <div class="action-links">
                    <a class="action-link" href="#">{{ $t('下單') }}</a>
                    <a class="action-link" href="#">{{ $t('拆枱') }}</a>
                    <a  class="action-link"href="#">{{ $t('鎖枱') }}</a>
                </div>
            </td>
            </tr>
            <tr>
            <td>42</td>
            <td>可用餐 (開放中)</td>
            <td>
                <div class="action-links">
                    <a class="action-link" href="#">{{ $t('下單') }}</a>
                    <a class="action-link" href="#">{{ $t('拆枱') }}</a>
                    <a  class="action-link"href="#">{{ $t('鎖枱') }}</a>
                </div>
            </td>
            </tr>
            <tr>
            <td>43</td>
            <td>用餐中 (開放中)</td>
            <td>
                <div class="action-links">
                    <a class="action-link" href="#">{{ $t('下單') }}</a>
                    <a class="action-link" href="#">{{ $t('拆枱') }}</a>
                    <a  class="action-link"href="#">{{ $t('鎖枱') }}</a>
                </div>
            </td>
            </tr>
            <tr>
            <td>44</td>
            <td>可用餐 (開放中)</td>
            <td>
                <div class="action-links">
                    <a class="action-link" href="#">{{ $t('下單') }}</a>
                    <a class="action-link" href="#">{{ $t('拆枱') }}</a>
                    <a  class="action-link"href="#">{{ $t('鎖枱') }}</a>
                </div>
            </td>
            </tr>
            <tr>
            <td>45</td>
            <td>用餐中 (開放中)</td>
            <td>
                <div class="action-links">
                    <a class="action-link" href="#">{{ $t('下單') }}</a>
                    <a class="action-link" href="#">{{ $t('拆枱') }}</a>
                    <a  class="action-link"href="#">{{ $t('鎖枱') }}</a>
                </div>
            </td>
            </tr>
            <tr>
            <td>46</td>
            <td>可用餐 (開放中)</td>
            <td>
                <div class="action-links">
                    <a class="action-link" href="#">{{ $t('下單') }}</a>
                    <a class="action-link" href="#">{{ $t('拆枱') }}</a>
                    <a  class="action-link"href="#">{{ $t('鎖枱') }}</a>
                </div>
            </td>
            </tr>
            <tr>
            <td>51</td>
            <td>可用餐 (開放中)</td>
            <td>
                <div class="action-links">
                    <a class="action-link" href="#">{{ $t('下單') }}</a>
                    <a class="action-link" href="#">{{ $t('拆枱') }}</a>
                    <a  class="action-link"href="#">{{ $t('鎖枱') }}</a>
                </div>
            </td>
            </tr>
            <tr>
            <td>52</td>
            <td>用餐中 (開放中)</td>
            <td>
                <div class="action-links">
                    <a class="action-link" href="#">{{ $t('下單') }}</a>
                    <a class="action-link" href="#">{{ $t('拆枱') }}</a>
                    <a  class="action-link"href="#">{{ $t('鎖枱') }}</a>
                </div>
            </td>
            </tr>
            <tr>
            <td>53</td>
            <td>可用餐 (開放中)</td>
            <td>
                <div class="action-links">
                    <a class="action-link" href="#">{{ $t('下單') }}</a>
                    <a class="action-link" href="#">{{ $t('拆枱') }}</a>
                    <a  class="action-link"href="#">{{ $t('鎖枱') }}</a>
                </div>
            </td>
            </tr>
            <tr>
            <td>54</td>
            <td>可用餐 (開放中)</td>
            <td>
                <div class="action-links">
                    <a class="action-link" href="#">{{ $t('下單') }}</a>
                    <a class="action-link" href="#">{{ $t('拆枱') }}</a>
                    <a  class="action-link"href="#">{{ $t('鎖枱') }}</a>
                </div>
            </td>
            </tr>
            <tr>
            <td>55</td>
            <td>用餐中 (開放中)</td>
            <td>
                <div class="action-links">
                    <a class="action-link" href="#">{{ $t('下單') }}</a>
                    <a class="action-link" href="#">{{ $t('拆枱') }}</a>
                    <a class="action-link"href="#">{{ $t('鎖枱') }}</a>
                </div>
            </td>
            </tr>
            <tr>
            <td>61V</td>
            <td>用餐中 (開放中)</td>
            <td>
                <div class="action-links">
                    <a class="action-link" href="#">{{ $t('下單') }}</a>
                    <a class="action-link" href="#">{{ $t('拆枱') }}</a>
                    <a class="action-link"href="#">{{ $t('鎖枱') }}</a>
                </div>
            </td>
            </tr>
            <tr>
            <td>62V</td>
            <td>可用餐 (開放中)</td>
            <td>
                <div class="action-links">
                    <a class="action-link" href="#">{{ $t('下單') }}</a>
                    <a class="action-link" href="#">{{ $t('拆枱') }}</a>
                    <a class="action-link"href="#">{{ $t('鎖枱') }}</a>
                </div>
            </td>
            </tr>
            <tr>
            <td>71</td>
            <td>可用餐 (開放中)</td>
            <td>
                <div class="action-links">
                    <a class="action-link" href="#">{{ $t('下單') }}</a>
                    <a class="action-link" href="#">{{ $t('拆枱') }}</a>
                    <a class="action-link"href="#">{{ $t('鎖枱') }}</a>
                </div>
            </td>
            </tr>
            <tr>
            <td>72</td>
            <td>可用餐 (開放中)</td>
            <td>
                <div class="action-links">
                    <a class="action-link" href="#">{{ $t('下單') }}</a>
                    <a class="action-link" href="#">{{ $t('拆枱') }}</a>
                    <a class="action-link"href="#">{{ $t('鎖枱') }}</a>
                </div>
            </td>
            </tr>
            <tr>
            <td>73</td>
            <td>可用餐 (開放中)</td>
            <td>
                <div class="action-links">
                    <a class="action-link" href="#">{{ $t('下單') }}</a>
                    <a class="action-link" href="#">{{ $t('拆枱') }}</a>
                    <a class="action-link"href="#">{{ $t('鎖枱') }}</a>
                </div>
            </td>
            </tr>
            <tr>
            <td>74</td>
            <td>用餐中 (開放中)</td>
            <td>
                <div class="action-links">
                    <a class="action-link" href="#">{{ $t('下單') }}</a>
                    <a class="action-link" href="#">{{ $t('拆枱') }}</a>
                    <a class="action-link"href="#">{{ $t('鎖枱') }}</a>
                </div>
            </td>
            </tr>
            <tr>
            <td>75</td>
            <td>用餐中 (開放中)</td>
            <td>
                <div class="action-links">
                    <a class="action-link" href="#">{{ $t('下單') }}</a>
                    <a class="action-link" href="#">{{ $t('拆枱') }}</a>
                    <a class="action-link"href="#">{{ $t('鎖枱') }}</a>
                </div>
            </td>
            </tr>
            <tr>
            <td>76</td>
            <td>用餐中 (開放中)</td>
            <td>
                <div class="action-links">
                    <a class="action-link" href="#">{{ $t('下單') }}</a>
                    <a class="action-link" href="#">{{ $t('拆枱') }}</a>
                    <a class="action-link"href="#">{{ $t('鎖枱') }}</a>
                </div>
            </td>
            </tr>
            <tr>
            <td>77</td>
            <td>用餐中 (開放中)</td>
            <td>
                <div class="action-links">
                    <a class="action-link" href="#">{{ $t('下單') }}</a>
                    <a class="action-link" href="#">{{ $t('拆枱') }}</a>
                    <a class="action-link"href="#">{{ $t('鎖枱') }}</a>
                </div>
            </td>
            </tr>
            <tr>
            <td>78</td>
            <td>用餐中 (開放中)</td>
            <td>
                <div class="action-links">
                    <a class="action-link" href="#">{{ $t('下單') }}</a>
                    <a class="action-link" href="#">{{ $t('拆枱') }}</a>
                    <a class="action-link"href="#">{{ $t('鎖枱') }}</a>
                </div>
            </td>
            </tr>
          </tbody>
        </table>  
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState, mapActions } from 'vuex';
  import ItemFilter from '@/components/Filter.vue';
  import Common from '@/lib/common';
  import Member from '@/lib/member';
  
  export default {
    name: 'Members',
    components: {
      ItemFilter,
    },
    mounted(){
      this.loadAllMembers();
    },
    methods:{
      async loadAllMembers(){
        try{
          const loginInfo = Common.getLoginInfo();
          const members = await Member.loadAllMembers(this.apiUrl, loginInfo);
          this.members = members;
        }catch(err){
          console.log(err);
        }
      },
    },
    data(){
      return {
        members: [],
        orignalMembers: [],
      }
    },
    computed: mapState({
     apiUrl: state => state.apiUrl,
   }),
  }
  </script>
  
  <style scoped lang="scss">
  .content-header{
    display: flex;
    h2{
      flex: 1;
    }
    .action-button{
      margin-right: 20px;
    }
  }
  .seat-list{
    display: flex;
    flex-wrap: wrap;
    margin-left: 20px;
    .card{
      margin-bottom: 30px;
      margin-right: 20px;
      .btn-primary{
        margin-right: 30px;
      }
    }
  }
  </style>
  